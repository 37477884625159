<template>
  <v-container fluid class="estimations">
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :search-placeholder="config.searchPlaceholder"
            :loaded-options="options"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            :total-elements="totalElements"
            :total-pages="totalPages"
            @onChangeData="onChangeData"
        >
          <template #last-slot>
            <div class="export-excel-btn">
              <v-btn rounded
                     class="mr-3"
                     color="success"
                     :loading="exportLoading"
                     @click="exportExcel">
                <v-icon>mdi-file-excel</v-icon>
                ВЫГРУЗКА
              </v-btn>
            </div>
          </template>
        </crm-table-pageable-new>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import moment from 'moment';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'orderId', sortable: false, text: 'Заказ №', type: 'defaultItem',
        },
        {
          value: 'userName', sortable: false, text: 'Имя пользователя', type: 'defaultItem',
        },
        {
          value: 'created', sortable: true, text: 'Дата оценки', type: 'defaultItem',
        },
        {
          value: 'estimationNumber', sortable: false, text: 'Оценка', type: 'defaultItem',
        },
        {
          value: 'estimationDelivery', sortable: false, text: 'Скорость доставки', type: 'tableBool',
        },
        {
          value: 'estimationService', sortable: false, text: 'Сервис', type: 'tableBool',
        },
        {
          value: 'estimationPrice', sortable: false, text: 'Цена', type: 'tableBool',
        },
        {
          value: 'estimationPayment', sortable: false, text: 'Удобство оплаты', type: 'tableBool',
        },
        {
          value: 'estimationApp', sortable: false, text: 'Удобство приложения', type: 'tableBool',
        },
        {
          value: 'comment', sortable: false, text: 'Комментарий', type: 'defaultItem',
        },
      ],
      data: [],
      config: {
        search: true,
        searchPlaceholder: 'Поиск по номеру заказа',
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: true,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      url: '/v1.0/Estimation',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },
      exportLoading: false,
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getEstimations();
    },
    getEstimations() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$estimations.get(formattedUrl, {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          CreatedStartDate: params.createdDateRange && params.createdDateRange.split(',')[0],
          CreatedEndDate: params.createdDateRange && params.createdDateRange.split(',')[1],
          OrderId: params.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / response.pageSize);
        this.data = response.data.map((i) => ({
          ...i,
          created: i.created ? moment(i.created).format('DD.MM.YYYY HH:mm') : i.created,
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
    exportExcel() {
      const params = this.$route.query;

      this.$loading(true);
      this.$estimations.get(`${this.url}/report-xls`, {
        params: {
          CreatedStartDate: params.createdDateRange && params.createdDateRange.split(',')[0],
          CreatedEndDate: params.createdDateRange && params.createdDateRange.split(',')[1],
          OrderId: params.search,
        },
        responseType: 'blob',
      }).then((response) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response);
        a.download = 'Оценки заказа';
        a.click();
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style lang="scss">
.estimations {
  .export-excel-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -42px;
  }
}
</style>
